<template>
  <div>
    <div class="order-table-wrapper">
      <div class="e-card">
        <div class="e-card-body" style="padding-top:35px">

          <div style="margin-bottom: 35px">
            <div class="handle-box">
              <span class="v-form-label">分类名称:</span>
              <el-input placeholder="分类名称" size="small" style="width:265px" v-model="name" clearable
                        class="handle-input mr10"></el-input>
              <el-row type="flex" justify="end" style="margin-left: 30px">
                <el-button size="small" class="e-button" type="primary" @click="searchEvent">查询
                </el-button>
                <el-button size="small" class="e-button" type="success" @click="addEvent()">新增
                </el-button>
              </el-row>
            </div>
          </div>
          <div class="e-table-wrapper">
            <table cellpadding="0" cellspacing="0" border="0" width="100%" style="text-align: center">
              <thead>
              <tr>
                <th width="130" class="th-l">分类名称</th>
                <th width="130" class="th-l">分类下商品数量</th>
                <th width="130" class="th-l">排序</th>
                <th width="160" class="th-l">创建时间</th>
                <th width="200" class="th-l">操作</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(row,index) in list" :key="row.id" :class="{ on: index % 2 == 0, off: index % 2 != 0 }">
                <td class="td-l"><span>{{row.categoryName}}</span></td>
                <td class="td-l"><span>{{row.skuNum}}</span></td>
                <td class="td-l"><span>{{row.sort}}</span></td>
                <td class="td-l"><span>{{row.createTime}}</span></td>
                <td class="td-l">
                  <el-button type="text" icon="el-icon-edit" @click="editEvent(index)">编辑</el-button>
                  <el-button type="text" icon="el-icon-delete" class="red" @click="removeEvent(index)">删除</el-button>
                </td>
              </tr>
              </tbody>
            </table>
            <div class="pagination">
              <el-pagination
                background
                @current-change="handleCurrentChange"
                :pager-count="7"
                layout="total, prev, pager, next,jumper"
                :total="pagination.count"
                :page-count="pagination.pages"
                :current-page="pagination.pageNumber"
              ></el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="新增" width="610px" v-if="addVisible" :visible.sync="addVisible" :before-close="closeAddEvent">
      <el-form ref="form" label-width="100px">
        <el-form-item size="small" label="分类名称:" required>
          <el-input v-model="categoryName" style="width: 450px" maxlength="64" show-word-limit></el-input>
        </el-form-item>
        <el-form-item size="small" label="排序:" required>
          <el-input v-model="sort" style="width: 450px" maxlength="10" show-word-limit></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="closeAddEvent">取 消</el-button>
            <el-button size="small" type="primary" @click="saveMspCategory">确 定</el-button>
        </span>
    </el-dialog>

    <el-dialog title="编辑" width="610px" :visible.sync="editVisible" :before-close="closeEditEvent">
      <el-form ref="form" label-width="100px">
        <el-form-item size="small" label="分类名称:" required>
          <el-input v-model="item.categoryName" style="width: 450px" maxlength="64" show-word-limit></el-input>
        </el-form-item>
        <el-form-item size="small" label="排序:" required>
          <el-input v-model="item.sort" style="width: 450px" maxlength="10" show-word-limit></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="closeEditEvent">取 消</el-button>
            <el-button size="small" type="primary" @click="updateMspCategory">确 定</el-button>
        </span>
    </el-dialog>

    <!-- 删除提示框 -->
    <el-dialog title="提示" :visible.sync="delVisible" width="300px" center>
      <div class="del-dialog-cnt">删除不可恢复，是否确定删除？</div>
      <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="delVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="delMethod">确 定</el-button>
            </span>
    </el-dialog>
  </div>
</template>

<script>

  export default {
    data() {
      return {
        name: '',
        sort: '',
        categoryName: '',

        list: [],
        pagination: {
          count: 0,
          pageNumber: 0,
          pageSize: 0,
          pages: 1
        },

        addVisible: false,
        editVisible: false,
        delVisible: false,
        editItem: {},
        item: {},

      }
    },
    async created() {
      this.searchEvent();
    },
    methods: {

      handleCurrentChange(page) {
        this.requestSearch(page)
      },
      searchEvent() {
        this.requestSearch()
      },
      async requestSearch(page) {
        let currentPage = page || 1;
        let data = {
          categoryName: this.name,
          categoryType: '0',
          isDel: '0',
          size: this.size == '' ? '10' : this.size,
          currentPage
        }
        let res = await this.$get("/admin/getMspCategoryForPage", data)
        let list = res.dataSet.list;
        this.list = list;
        this.pagination = {
          count: res.count,
          pageNumber: res.pageNumber,
          pageSize: res.pageSize,
          pages: res.pages
        }
      },
      async saveMspCategory() {
        let data = {
          categoryName: this.categoryName,
          sort: this.sort,
          categoryType: '0',
        };

        if (!this.categoryName) {
          this.$message.error("请填写分类名称");
          return
        }

        let res = await this.$post("/admin/save_mspCategory", data)
        if (res.code == 200) {
          this.$message.success(res.message);
          this.searchEvent()
          this.addVisible = false
        } else {
          this.$message.error(res.message);
        }
      },

      async updateMspCategory() {
        let data = {
          id: this.item.id,
          categoryName: this.item.categoryName,
          sort: this.item.sort,
        };

        if (!this.item.categoryName) {
          this.$message.error("请填写分类名称");
          return
        }
        let res = await this.$post("/admin/save_mspCategory", data)
        if (res.code == 200) {
          this.$message.success(res.message);
          this.searchEvent()
          this.editVisible = false
        } else {
          this.$message.error(res.message);
        }
      },

      addEvent() {
        this.categoryName=''
        this.addVisible = true
      },
      closeAddEvent() {
        this.addVisible = false
      },

      closeEditEvent() {
        this.editVisible = false
      },

      editEvent(index) {
        this.item = this.list[index]
        this.editVisible = true
      },

      async removeEvent(index) {
        this.editItem = this.list[index]
        this.delVisible = true;
      },

      //删除
      async delMethod() {
        let data = {
          id: this.editItem.id
        };
        let res = await this.$post("/admin/delete_mspCategory", data)
        if (res.code == 200) {
          this.searchEvent();
          this.$message.success(res.message);
          this.delVisible = false;
        } else {
          this.$message.error(res.message);
        }
      },

    },
  }

</script>

<style scoped>
  .th-l {
    text-align: center;
    padding-right: 10px;
    font-size: 14px;
  }

  .td-l {
    padding: 10px 12px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }

  .e-table-wrapper tbody .el-button {
    padding: 3px 0px;
  }
   .off {
  background-color: #fff;
}
.on {
  background-color: #ececea;
}
</style>
