<template>
  <div>
    <div class="e-breadcrumb">分类设置</div>
    <div class="order-table-wrapper">
      <div class="e-card">
        <div class="e-card-body" style="padding-top:35px">
          <div class="e-card-tabs">
            <div class="e-tabs-wrapper">
              <div class="e-tabs-item " :class="{'active':tabIndex==0}" @click="switchTable(0)">服务类
              </div>
              <div class="e-tabs-item" :class="{'active':tabIndex==1}" @click="switchTable(1)">卖品类
              </div>
            </div>
          </div>
          <TabService v-if="tabIndex==0"/>
          <TabSale v-if="tabIndex==1"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TabSale from '../category/saleEntry.vue'
  import TabService from '../category/serviceEntry.vue'

  export default {
    data() {
      return {
        tabIndex: 0,
        item: {},
      }
    },
    async created() {
    },
    methods: {

      switchTable(tabIndex) {
        this.tabIndex = tabIndex
      },

    },
    components: {
      TabSale,
      TabService,
    }
  }

</script>

<style scoped>
  .e-table-wrapper tbody .el-button {
    padding: 3px 0px;
  }
</style>
